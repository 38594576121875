@tailwind base;
@tailwind components;
@tailwind utilities;

/* Google fonts
a) Open Sans
b)Montserrat
 */

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

h1,
h2,
h3,
h4,
h5,
h6,
span {
  font-family: "Montserrat";
  font-optical-sizing: auto;
  font-weight: auto;
  font-style: normal;
}
p,
button {
  font-family: "Open Sans";
  font-optical-sizing: auto;
  font-weight: auto;
  font-style: normal;
}
